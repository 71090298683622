import React from "react";
import "./Footer.scss";
import { Image } from "react-bootstrap";
import Logo from "../../assets/logo-sbl.svg";
import { Link } from "react-router-dom";

import Twitter from "../../assets/X-icon.png";
// import Telegram from "../../assets/telegram.png";
// import discord from "../../assets/discord.png";
// import Instagram from "../../assets/instagram.png";
// import Tiktok from "../../assets/tik-tok.png";

const Footer = () => {
  return (
    <div className="container-fluid">
      <div className=" Xario_footer row py-4">
        <div className=" col-12  col-md-2 col-lg-1 d-flex justify-content-center">
          <Image src={Logo} alt={Logo} width={70} />
        </div>
        <div className=" col-12 col-md-10 col-lg-11 ">
          <div className=" d-flex justify-content-end row me-lg-5">
            <div
              className=" col-6 col-lg-3 mb-4 row"
              style={{ justifyItems: "center" }}
            >
              <Link
                to={"/"}
                className=" text-white text-decoration-none col-6 m-auto"
              >
                <h5>Terms of Use</h5>
              </Link>
              <Link
                to={"/"}
                className=" text-white text-decoration-none col-6 m-auto"
              >
                <h5>Privacy policy</h5>
              </Link>
            </div>
            <div
              className=" col-6 col-lg-6 d-grid mb-4"
              style={{ justifyItems: "center" }}
            >
              <h4>
                <b>Contact</b>
              </h4>

              <Link
                to="mailto:connect@chizle.ai"
                className=" text-white text-decoration-none "
              >
                <h5>email:connect@chizle.ai</h5>
              </Link>
            </div>
            <div
              className=" col-12 col-lg-3 d-grid mb-4"
              style={{ justifyItems: "center" }}
            >
              <h4>
                <b>Socials</b>
              </h4>
              <div>
                {/* <Link
                  to={"https://t.me/xarioai"}
                  target="_blank"
                  className=" text-white text-decoration-none "
                >
                  <Image
                    src={Telegram}
                    alt={Telegram}
                    width={20}
                    className=" mx-3"
                  />
                </Link> */}
                <Link
                  to={"https://twitter.com/XarioAI"}
                  target="_blank"
                  className=" text-white text-decoration-none "
                >
                  <Image
                    src={Twitter}
                    alt={Twitter}
                    width={20}
                    className=" mx-3"
                  />
                </Link>
                {/* <Link
                  to={"https://discord.gg/Cf7X7kbdqF"}
                  target="_blank"
                  className=" text-white text-decoration-none "
                >
                  <Image
                    src={discord}
                    alt={discord}
                    width={20}
                    className=" mx-3"
                  />
                </Link> */}
                {/* <Link
                  to={"https://www.instagram.com/xarioai/"}
                  target="_blank"
                  className=" text-white text-decoration-none "
                >
                  <Image
                    src={Instagram}
                    alt={Instagram}
                    width={20}
                    className=" mx-3"
                  />
                </Link> */}
                {/* <Link
                  to={"https://www.tiktok.com/@xarioai"}
                  target="_blank"
                  className=" text-white text-decoration-none "
                >
                  <Image
                    src={Tiktok}
                    alt={Tiktok}
                    width={20}
                    className=" mx-3"
                  />
                </Link> */}
              </div>
            </div>
          </div>
          {/* <div className=" d-flex justify-content-center">
            <p className="  text-capitalize fs-5 mb-2 ">
              xario AI is a product of chizle technologies LLC registered
              with common wealth of california state corporation commision (San Francisco). the
              buisnes is subjected all laws applicable by state of california & USA
            </p>
          </div> */}
          {/* <div className=" d-flex justify-content-center">
            <p className="  text-capitalize fs-5 mb-2 ">
              Xario is a brand of Chizle Technologies LLC, registered with the
              Corporation Commission of Virginia. The business is subject to all
              applicable state laws.
            </p>
          </div> */}
          <div className=" d-flex justify-content-center">
            <p className="  text-capitalize fs-5 mb-2 ">
              Xario is a brand of Chizle Technologies LLC, registered with the
              Corporation Commission of Virginia.
            </p>
          </div>
          <div className=" d-flex justify-content-center">
            <p className=" fs-5 m-0">© 2024 All Rights Reserved by XARIO.AI</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
