import React, { useEffect, useRef, useState } from "react";
import { Image } from "react-bootstrap";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { Twitter } from "@mui/icons-material";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import SendIcon from "@mui/icons-material/Send";
import ApiRepository from "../../API/ApiRepository";
import "./index.scss";
import xarioLogo from "../../assets/logo-sbl.svg";
import userIcon from "../../assets/xarioLogo.png";
import addbtn from "../../assets/add-30.png";
import msgIcon from "../../assets/message.svg";
import Typewriter from "../Animaction/Typewriter";
import Typewritertwo from "../Animaction/Animaction-typing";

const XarioAI = () => {
  const msgEnd = useRef(null);
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([
    {
      text: "Hi, this is Xario. Ask me anything about crypto!",
      isBot: true,
    },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);

  // Scroll to the last message whenever the messages array updates
  useEffect(() => {
    msgEnd.current.scrollIntoView();
  }, [messages]);

  // Send message to API
  const handleSendMessage = async (text) => {
    setIsLoading(true);
    
    // Update the chat with the user's message
    setMessages((prevMessages) => [
      ...prevMessages,
      { text, isBot: false }
    ]);

    try {
      // Call your API and get the response
      const response = await ApiRepository.fetchChatMessage(text);
      
      const Data = {
        name: response.name || "",
        symbol: response.symbol || "",
        currentPrice: response.current_price_usd
          ? response.current_price_usd.toFixed(2)
          : "",
        analysis: response.analysis || "",
        message: response.message || "",
        response: response.response || "",
      };

      const formattedResponse = `
        ${Data.name} ${Data.symbol} ${Data.currentPrice}
        ${Data.analysis} ${Data.message} ${Data.response}
      `;

      // Add the bot's response to the chat
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: formattedResponse, isBot: true }
      ]);
      
    } catch (error) {
      console.error("Error fetching data:", error);
      // Optionally, display an error message in the chat
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: "Error fetching data.", isBot: true }
      ]);
    } finally {
      setIsLoading(false); // Stop the loading spinner
    }
  };

  // Handle form submission on Enter key press
  const handleEnter = (e) => {
    if (e.key === "Enter" && input.trim() !== "") {
      handleSendMessage(input);
      setInput(""); // Clear the input after sending
    }
  };

  // Handle button clicks for pre-defined queries
  const handleQuery = (e) => {
    const query = e.target.value;
    if (query.trim()) {
      handleSendMessage(query);
    }
  };

  return (
    <div className={`App container-fluid row ${toggleMenu ? "sidebar-open" : "sidebar-closed"}`}>
      <div className={`col-1 ${toggleMenu ? "open sidebar col-lg-2 col-12" : ""}`}>
        {toggleMenu ? (
          <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
        ) : (
          <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />
        )}
        {toggleMenu && (
          <>
            {/* Sidebar content */}
            <div className="upperSide p-4 row">
              <Link to={"/"} className="upperSideTop d-flex justify-content-center align-items-center mb-4 text-decoration-none text-white">
                <Image src={xarioLogo} width={58} alt="Logo" />
              </Link>
              <Button onClick={() => window.location.reload()} className="midBtn col-12 text-white p-1 fs-4">
                <Image src={addbtn} alt="New Chat" className="addBtn me-2" /> New Chat
              </Button>
            </div>
            <div className="MidSide p-4 row">
              {/* Pre-defined query buttons */}
              {["BTC", "ETH", "SOL", "AVAX"].map((crypto) => (
                <Button key={crypto} onClick={handleQuery} value={crypto} className="query p-1 text-white p-md-3 col-12 mb-1">
                  <Image src={msgIcon} alt={crypto} className="addBtn me-4" /> {crypto}
                </Button>
              ))}
            </div>
            <div className="secondLastSide p-4">
              <Button className="midBtn col-12 text-white p-2 fs-4">Login</Button>
            </div>
            <div className="lowerSide p-3 ">
                <h5 className="listitems m-0">Connect with us on</h5>
                <Link
                  to={"https://twitter.com/XarioAI"}
                  target="_blank"
                  className="listitems"
                >
                  <Twitter />
                </Link>
            </div>
          </>
        )}
      </div>

      <div className={`row p-1 p-md-5 main col-12 col-md-12 col-lg-10 ${toggleMenu ? "col-lg-10" : "col-lg-11"}`}>
        <div className="chats overflow-auto col-12 col-lg-10">
          {messages.map((message, i) => (
            <div key={i} className={message.isBot ? "chat bot" : "chat"}>
              <Image className="chatimg" src={message.isBot ? userIcon : userIcon} alt={message.isBot ? "Bot" : "User"} />
              <p className="txt">
                {message.isBot ? <Typewriter text={message.text} /> : message.text}
                {!message.isBot && isLoading && <Typewritertwo text="Xario is fetching info..." />}
              </p>
              <div ref={msgEnd} />
            </div>
          ))}
        </div>

        <div className="chatFooter mt-2 col-12 col-lg-10">
          <div className="inp">
            <input
              type="text"
              placeholder="Enter a cryptocurrency and do a knowledge search."
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={handleEnter}
              className="text-white"
            />
            <Button className="send position-static text-white" onClick={() => handleSendMessage(input)}>
              <SendIcon fontSize="large" />
            </Button>
          </div>
          <p className="gptnotic"> Consider checking important information.</p>
        </div>
      </div>
    </div>
  );
};

export default XarioAI;
